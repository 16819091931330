<div class="jumbotron p-2">
  <div class="container-fluid">
    <h1 class="display-4 text-center">Worplesdon Memorial Hall and Recreation Ground CIO</h1>
    <br>
    <img style="margin:auto;display: block;width:750px" src="../assets/Bookkeeper Ad.png">
    <br>
    <ngb-carousel [interval]="3000" class="carousel slide" style="max-width: 750px;margin: auto;margin-bottom: 20px;">
      <ng-template ngbSlide *ngFor="let img of grounds">
        <div class="picsum-img-wrapper">
          <img [src]="img.url" [alt]="img.title">
        </div>
      </ng-template>
    </ngb-carousel>
    <p>
      Worplesdon Memorial Hall is a popular village hall near Guildford in Surrey. With its central position in the
      village, easy access from Guildford and adequate parking, the hall is an ideal venue for all kinds of social
      activities, public or private, including weddings.</p>
    <p>There is good access to the hall for the disabled, which is facilitated by the availability of
      parking next to the hall entrance. The toilets have disabled facilities.</p>
    <p><b>Free wi-fi</b> is available throughout the building.</p>
    <p>Our address is: <a target="_blank"
        href="https://www.google.co.uk/maps/place/Worplesdon+Memorial+Hall/@51.2770657,-0.6121381,17z/data=!4m5!3m4!1s0x4875d6d80318fa25:0x8ac1e5c8b889d890!8m2!3d51.2770626!4d-0.6101308">
        Worplesdon Memorial Hall, Perry Hill, Worplesdon, Guildford, Surrey GU3 3RF</a></p>
    <a class="btn btn-primary btn-large" routerLink="/contact">Contact Us</a>
  </div>
</div>
<div class="container">
  <div class="row">
    <div class="col-md-4">
      <h2>Sports</h2>
      <p>With a Tennis Club, Cricket Club and a Football Club as well as a children's playground, Worplesdon Memorial
        Hall is the ideal venue for your sporting activities</p>
      <p><a class="btn btn-secondary" routerLink="/sports">Learn More</a> </p>
    </div>
    <div class="col-md-4">
      <h2>Events</h2>
      <p>From holding dance classes to your Wedding, we are your number one local venue</p>
      <p><a class="btn btn-secondary" routerLink="/wedding">Learn More</a> </p>
    </div>
    <div class="col-md-4">
      <h3>Sidney Sime Gallery</h3>
      <img src="assets/sidney_sime.jpg">
      <p>On the first floor of the Hall there is
        a small gallery devoted to the fascinating and
        highly characteristic work of Sidney Sime (1865-1941),
        the famous artist, illustrator and caricaturist. Sime owned a cottage at Worplesdon and spent much of his
        time here during
        the latter part of his life.</p>
      <p><a class="btn btn-secondary" href="https://www.sidneysimegallery.org.uk">Learn More</a> </p>
    </div>
  </div>
</div>
