<div class="row" style="height: 900px;">
  <div class="col-sm-4" style="max-width: 750px;">
    <ngb-carousel [interval]="3000" class="carousel slide" style="width: 100%;">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/halsey1.jpg" class="d-block w-100" alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/halsey2.jpg" class="d-block w-100" alt="">
        </div>
      </ng-template>
    </ngb-carousel>
    <a href="../../assets/rooms/halsey floorplan.png" target="_blank">
      <img src='../../assets/rooms/halsey floorplan.png' style="width: 100%;">
    </a>
  </div>
  <div class="col-sm-8">
    <b>Can seat up to 40 people, depending on configuration.
      6ft trestle tables and chairs available for use at no extra cost.
      PLEASE NOTE: Hire does not include use of the kitchen. Access to toilets is through Walker Room or via outside
      covered area.</b>
    <p>Our second largest room, this space is suitable for events such as meetings, talks or smaller gatherings.
      Hirers booking the Walker Room for parties or similar events may wish to consider also hiring the Halsey room as a
      second space for use as a bar area or quieter room.</p>
    <iframe src='https://hallbookingonline.com/worplesdon/plugin.php?filter=halsey' width='100%' height='100%'>
    </iframe>
  </div>
</div>
