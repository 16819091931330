<div class="Site-content">
  <div class="row">
    <div class="col-sm-12">
      <nav class="navbar navbar-expand-md navbar-light">
        <ul class="nav navbar-nav">
          <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="walker">Walker</a>
          </li>
          <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="halsey">Halsey</a>
          </li>
          <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="tovey">Tovey</a>
          </li>
          <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="cater">Cater</a>
          </li>
          <li routerLinkActive="active" class="nav-item">
            <a class="nav-link" routerLink="kelly">Kelly</a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
