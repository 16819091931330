<div class="row" style="height: 900px;">
  <div class="col-sm-4" style="max-width: 750px;">
    <ngb-carousel [interval]="3000" class="carousel slide" style="width: 100%">
      <ng-template *ngFor="let item of pictures" ngbSlide>
        <div class="picsum-img-wrapper">
          <img [src]="url(item)" alt="" class="d-block w-100">
        </div>
      </ng-template>
    </ngb-carousel>
    <a href="../../assets/rooms/tovey floorplan.png" target="_blank">
      <img src='../../assets/rooms/tovey floorplan.png' style="width: 100%">
    </a>
  </div>
  <div class="col-sm-8">
    <b>Our largest meeting room. Can seat 30 end on or 20 at tables.
      6ft trestle tables and chairs available for use at no extra cost.
      Equipped with kitchenette, fridge and whiteboard. Outside toilets directly opposite.</b>
    <p>This self-contained room with its own external entrance is ideal for meetings, training sessions or talks.
      It is a flexible space that can be set up to suit the event, with comfortable chairs and trestle tables which can
      be
      configured as a rectangle to seat 16. The small kitchenette includes tea and coffee making facilities, fridge and
      sink.
      The room is directly opposite our outside toilets which have ambulant disabled facilities.</p>
    <iframe src='https://hallbookingonline.com/worplesdon/plugin.php?filter=tovey' width='100%' height='100%'>
    </iframe>
  </div>
</div>
