<div class="row" style="height: 900px;">
  <div class="col-sm-4" style="max-width: 750px;">
    <ngb-carousel [interval]="3000" class="carousel slide" style="width: 100%;">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/walker1.jpg" class="d-block w-100" alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/walker2.jpg" class="d-block w-100" alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/walker3.jpg" class="d-block w-100" alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/walker4.jpg" class="d-block w-100" alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/walker5.jpg" class="d-block w-100" alt="">
        </div>
      </ng-template>
    </ngb-carousel>
    <a href="../../assets/rooms/walker floorplan.png" target="_blank">
      <img src='../../assets/rooms/walker floorplan.png' style="width: 100%">
    </a>
  </div>
  <div class="col-sm-8">
    <b>Can seat 160 facing the stage, or up to 120 at tables, depending on configuration.
      20 6ft trestle tables and 120 chairs available for use at no extra cost.
      Hire of the Walker Room includes use of the kitchen and toilets.</b>
    <p>A light and spacious hall with a raised stage at one end this is a versatile space, perfect for parties. Eight
      built-in benches run either side providing convenient seating, with plenty of extra chairs and tables available.
      The hardwood floor is a great dancefloor, used every week by our resident dance groups and the stage, which
      contains a
      grand piano, is ideal for a band, DJ or other entertainment.
      Hire of the Walker Room includes use of our modern, well-equipped kitchen, which includes a large range cooker,
      washing machine, two fridges, freezer, hot water urn and microwave.
      There is easy access to the toilet facilities, which comprise Ladies, Gentlemens and Disabled which includes baby
      change.
      Can be hired alongside the Halsey Room which is directly adjacent, if more space is required.</p>
    <iframe src='https://hallbookingonline.com/worplesdon/plugin.php?filter=walker' width='100%' height='100%'>
    </iframe>
  </div>
</div>
