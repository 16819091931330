<div class="row" style="height: 900px;">
  <div class="col-sm-4" style="max-width: 750px;">
    <ngb-carousel [interval]="3000" class="carousel slide" style="width: 100%;">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/kelly1.jpg" class="d-block w-100" alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/kelly2.jpg" class="d-block w-100" alt="">
        </div>
      </ng-template>
    </ngb-carousel>
    <a href="../../assets/rooms/kelly floorplan.png" target="_blank">
      <img src='../../assets/rooms/kelly floorplan.png' style="width: 100%">
    </a>
  </div>
  <div class="col-sm-8">
    <b>A self-contained meeting room. Can seat up to 16.
      PLEASE NOTE: This room is only accessible via a large staircase.</b>
    <p>With its own external entrance, this is a quiet, self-contained room, suitable for meetings or private working
      space.
      This room does not have access to the kitchen, however a kettle is available. Toilets available are the outside
      toilets at the other end of the building.</p>
    <iframe src='https://hallbookingonline.com/worplesdon/plugin.php?filter=kelly' width='100%' height='100%'>
    </iframe>
  </div>
</div>
