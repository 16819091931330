import { Component } from '@angular/core'
import { Image } from './sports.component'

@Component({
  templateUrl: './home.component.html'
})
export class HomeComponent {
  grounds: Image[] = [{
    url: '../assets/grounds/14.jpg',
    title: 'Title 1'
  }, {
    url: '../assets/grounds/15.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/16.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/17.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/18.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/19.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/20.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/21.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/22.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/23.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/24.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/25.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/26.jpg',
    title: 'Title 2'
  }, {
    url: '../assets/grounds/27.jpg',
    title: 'Title 2'
  }
  ]
}
