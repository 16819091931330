<div class="row" style="height: 900px;">
  <div class="col-sm-4" style="max-width: 750px;">
    <ngb-carousel [interval]="3000" class="carousel slide" style="width: 100%;">
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/cater1.jpg" class="d-block w-100"  alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/cater2.jpg" class="d-block w-100"  alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/cater3.jpg" class="d-block w-100"  alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/cater4.jpg" class="d-block w-100"  alt="">
        </div>
      </ng-template>
      <ng-template ngbSlide>
        <div class="picsum-img-wrapper">
          <img src="../../assets/rooms/cater5.jpg" class="d-block w-100"  alt="">
        </div>
      </ng-template>
    </ngb-carousel>
    <a href="../../assets/rooms/cater floorplan.png" target="_blank">
      <img src='../../assets/rooms/cater floorplan.png' style="width: 100%"></a>
  </div>
  <div class="col-sm-8">
    <b>Our smaller meeting room. Can seat up to 12 round a large table.
      Hire includes use of our main kitchen for tea and coffee making facilities.
      PLEASE NOTE: This room is only accessible via a large staircase.</b>
    <p>This space is perfect for smaller meetings of up to 12 people, with a large central table and comfortable
      armchairs.
      Accessed via a staircase from the kitchen anteroom, this room can also be hired in conjunction with the Walker
      Room as a changing room or band room for large events.</p>
    <iframe src='https://hallbookingonline.com/worplesdon/plugin.php?filter=cater' width='100%' height='100%'>
    </iframe>
  </div>
</div>
