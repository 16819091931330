<h1>Wedding receptions in the Hall</h1>
Prices for ceremony from £585. Price for reception from £1000.
Call us to discuss your special requirements – your numbers – your catering needs – your ceremony wish-list.
We are here to help.
Please Call:
For weddings: 01483 233091
For reception: 01483 232117
See also:
<a href="https://www.worplesdonparish.com" target="_blank">www.worplesdonparish.com</a>
<h2>The Village Reception</h2>

<div class="card-deck">
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic1.jpg" class="card-img-top">
    <div class="card-body">
      <p class="card-text">The Worplesdon Memorial Hall offers the flexibility to design your reception whichever way
        suits you best</p>
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic2.jpg" class="card-img-top">
    <div class="card-body">
      <p class="card-text">Whether a formal dinner or an informal buffet, the Memorial Hall makes sure that all your
        friends and family feel
        at
        home.</p>
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic3.jpg" class="card-img-top">
    <div class="card-body">
      <p class="card-text">A range of rooms are available so that your wedding party can change their outfits.
      </p>
    </div>
  </div>
</div>
<h2>Weddings at Worplesdon</h2>
<p>If you are thinking of tying the knot then think about being married here at Worplesdon.
  Worplesdon village offers a wonderful experience. A traditional church wedding followed by a village reception.
</p>
<div class="card-columns">
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic4.jpg" class="card-img-top">
    <div class="card-body">
      <p class="card-text">St. Mary’s Church, Worplesdon, offers a traditional wedding service in an historic setting.
      </p>
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic5.jpg" class="card-img-top">
    <div class="card-body">
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic9.jpg" class="card-img-top">
    <div class="card-body">
      <p class="card-text">From the Lych Gate, a footpath winds up to the church porch</p>
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic6.jpg" class="card-img-top">
    <div class="card-body">
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic7.jpg" class="card-img-top">
    <div class="card-body">
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic8.jpg" class="card-img-top">
    <div class="card-body">
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic9.jpg" class="card-img-top">
    <div class="card-body">
      <p class="card-text">The church interior retains its original feel, hardly changed from earlier times</p>
    </div>
  </div>

  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic10.jpg" class="card-img-top">
    <div class="card-body">
      <p class="card-text">Enjoy being married in the traditional way. Weddings have been conducted at St. Mary’s for
        many years</p>
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic11.jpg" class="card-img-top">
    <div class="card-body">
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic12.jpg" class="card-img-top">
    <div class="card-body">
      <p class="card-text">After the ceremony, enjoy a time with your photographer outside this historic village church.
      </p>
    </div>
  </div>
  <div class="card" style="width: 18rem;">
    <img src="../assets/wedding/pic13.jpg" class="card-img-top">
    <div class="card-body">
      <p class="card-text">Then move to the Memorial Hall in the village to continue your festivities.</p>
    </div>
  </div>
</div>
