<h2>History and Management</h2>
<h3>The Hall was built in 1922 in memory of the local men killed in World War I.</h3>
<p>The land had been donated to Worplesdon Parish Council for this purpose in 1919 by Sir James Lewis Walker
  (1845-1927), a local landowner who had enjoyed a distinguished career as a banker and newspaper owner in India. A
  portrait of Sir James can be seen in the hall. He lived in Worplesdon Place at the centre of an estate which included
  property on both sides of the A322. The house is now the Worplesdon Place Hotel, almost opposite the hall.</p>
<p>The Hall itself was built with funds from an anonymous donor, who was later identified as Sir Laurence Edward Halsey
  (1871-1945). Sir Laurence lived at Gooserye in Worplesdon and was a leading member of the accountancy profession,
  holding many distinguished appointments, including Auditor to the Duchy of Lancaster. He was High Sheriff of Surrey in
  1935.</p>
<p>Although it retains formal ownership of the land, the Parish Council is not responsible for the management of the
  property. Instead, the management of the Memorial Hall and Grounds is in the hands of the Worplesdon Memorial
  Trustees, a registered charity. (This is the registered working name of the charity; the full formal name is
  Worplesdon Memorial Hall and Recreation Ground and the Sime Trust.)</p>
<p>If you would like know more about the work of the trustees, you may like to see our most recent Annual Report and
  Accounts.</p>
<p>The trustees are committed to provide the residents of Worplesdon with recreational and social facilities. As
  volunters, they give their time and efforts to maintain the objectives of the Hall Trust as set out in the original
  Deed of 1919 and are always seeking to improve and expand the facilities for the benefit all users.</p>

<p><b>The Walker Room</b>, previously known as the Main Hall consists of a large 128.27 sqm open area as well as a Stage
  which
  is 38.47 sqm. Sir James Walker of Worplesdon Place who gave the land for the Memorial hall to be built on. He bought
  Worplesdon Place in about 1905 and lived their until he died in 1927. He was born in India in 1845. During his
  career he established the Alliance Bank of Simla. He was also a newspaper proprietor of two Indian periodicals. He
  was friend of Rudyard Kipling, who lived with Sir James and his first wife in India as a child. Sir James was
  Kiplings first patron and Rudyard Kipling even visited them at Worplesdon Place in 1911. Obviously Sir James spent
  lot of his life in India. Huge picture on wall is of Sir James Walker in main hall.</p>

<p><b>The Halsey Room</b>, previously known as the Small Hall consists of a 47.29 sqm open area.
  Sir Laurence Halsey (1871-1945) was the 1st Chairman and served 23 years (1921-1944). He gave the monies for the hall
  to
  be built. He is buried across at St Mary’s Worplesdon rest of his family in Pirbright. He did not marry or have a
  family
  of his own. He lived at Gooserye on Whitmoor common.</p>

<p><b>The Tovey Room</b>, previously known as the Conference Room consists of a 10.7 sqm open area.
  Tovey who was trustee from 1942-1952 Margaret Tovey was Hon secretary from 1948-1974.Rev Duncan Crookes Tovey
  1842-1912
  had been rector in St Mary’s Parish as well as Worplesdon Parish council chairman from 1899-1909. Plus had played huge
  part in village life while he lived here. His son also named Duncan died in WW1 in 1918 leaving widow and two sons and
  a
  daughter as children. Margaret Joan Woodd Walker later became the wife of one of the sons also named Duncan Lorimer
  Pattison Tovey 1903-1948. Margaret was also co- author of two history booklets produce called Notes on the history of
  Worplesdon and the other Worplesdon Old and New. ( named as Joan Tovey) she was very involved in village life and the
  heavily involved in WI and also of course neighbour of Sidney and Mary Sime recommending to her friend Mary to leave
  Sidney’s art work to the trustees of the Memorial hall for the purpose of exhibiting her husband’s collection of
  drawings and paintings bequeathing in her will monies to build a room at the hall. Sir Laurence Halsey had been
  consulted on this matter before his death.</p>

<p><b>The Cater Room</b>, previously known as the Committee Room.
  Terry Cater was the 8th Chairman of WMH from 2004-2018 as well as a Worplesdon Parish councillor for many years.
  Also as we know Terry’s history with all the hall and sport on the grounds must be second to none. 17 years a
  trustee/ chairman. Also his father was very heavily involved in both as well. Terry’s father was chair of Worplesdon
  Parish council during his years as a councillor from 1972-1973 then 1976-1985/6 until his death. Also the
  wicketkeeper for Fairlands cricket team. He was described by Surrey Advertiser as “the spirit of Worplesdon”
  something his son Terry has continued to do. During covid lockdown Terry volunteered to put new pictures
  up in the Sime Gallery no easy task for man of mature years. Terry has also been involved as Hon secretary of
  football club for 12 years and chairman of cricket club for 10 years and player of Worplesdon football and cricket
  clubs for 42 years and still watches matches . Also was Chairman of the committee responsible to the Worplesdon
  Parish council for the millennium book Worplesdon A Tale of Four Villages. He received Guildford Mayors award for
  community service. A families dedication to the well being of the Memorial hall and recreational grounds.
  As you can see both men 14 years chairman of WMH quite something. Obviously Sir Laurence Halsey the longest serving
  chairman. Harry his father was known as Mr Worplesdon, he was a member of the territorial army , he was one of the
  first to serve at Dunkirk and one of twenty in his unit to survive the Battle of Arnham. On his return to Worplesdon
  he became involved in Fairlands Liddington Hall and Gravetts lane community association FLGCA. He and other
  residents negotiated purchase of disused nissen hut on Fairlands later in 1963 involved in fund raising and building
  of current community centre. He later became president of FLGCA. He even was invited to Queen’s garden party for
  services to community.</p>

<p><b>The Kelly Room</b>, previously known as the Council Chambers consists of a 38.47 sqm located on the first floor,
  accessible from the stairs at the front of the building to the right of the main
  entrance. After, Mr Arthur Lindsay Kelly he was 2nd Chairman of WMH trustees , he also served 25 years as chairman
  of Worplesdon Parish council. He was also Worplesdon Parish council for 55 years. ( My understanding probably the
  longest serving WPC possibly the next is Sandra Morgan MBE 53 years currently) He died in 1963 he lived later part
  of his life at Highclere just over the road from the hall between the cottages and the road named The Avenue and
  before that at Hockley Lands on Burdenshott road recently renamed Hertford Park . A lifetime of public service to
  Surrey County an alderman since 1947. His funeral was attended by officials of SCC, Guildford rural council of which
  he had been a founder member of the executive, Woking magistrates court and hospital managements. He was a former
  chairman of WMH trustees 1945-1959 who was also a former chairman of Worplesdon Parish council. Arthur Lindsay Kelly
  1880-1963 was a very prominent figure all around in the Parish and St Mary’s church and community. He is seen in
  Worplesdon football photographs and he was a cricketer.</p>

<p><b>The Thompson Room</b>, previously known as the Anti-Room or Womens Institute (WI) Room.Winifred Amy Thompson not
  married was a trustee from 1939-1944 she was also prominent member of Womens institute (WI)
  and county WI agricultural committee having a jersey cow heard. She was VAD nurse in 1914-1918 and was 15 years the
  rectors warden at St Mary’s church for 3 rectors. She had two sisters and although not born here very prominent in
  their time here in Worplesdon.</p>
